import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./helpers/filter";
import "./helpers/mixin";

Vue.config.productionTip = false

import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker);

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper /* { default global options } */);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import axios from "axios";
Vue.prototype.$axios = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL_API}/api/`,
  headers: {
    Accept: "application/json"
  }
});

import cookie from "js-cookie";
if (cookie.getJSON("userdata") !== undefined) {
  let auth = cookie.getJSON("userdata");
  store.commit("SET_USERDATA", auth);
  Vue.prototype.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token
}
if (cookie.getJSON("avatar") !== undefined) {
  let data = cookie.getJSON("avatar");
  store.commit("SET_AVATAR", data.avatar);
}

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#FD7636'
})

import Echo from 'laravel-echo' 
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: false
});

Vue.mixin({
  data() {
    return { 
      ENV_MODE: (process.env.VUE_APP_ENV_MODE !== undefined)? process.env.VUE_APP_ENV_MODE : 'production',
      BASE_URL: process.env.VUE_APP_BASE_URL,
      SSO_URL: process.env.VUE_APP_SSO_URL
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
