import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    avatar: null
  },
  mutations: {
    SET_USERDATA(state, payload) {
      state.user = payload;
    },
    SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    }
  },
  getters: {
    userType(state) {
      if(state.user) {
        if (state.user.eduPersonAffiliation == 'Lecturer') {
          return 'master'
        } else {
          return 'student'
        }
      } else {
        return null
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
