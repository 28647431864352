<template>
  <div id="app">
    <Header></Header>
    <router-view :key="$route.fullPath" />
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header, Footer
  }
}
</script>
