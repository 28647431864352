<template>
  <footer class="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="footer-item"><img src="/img/logo-bottom.svg" class="img-fluid" alt="logo"></div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-item">
              <strong>
                Department of International Relations <br> Faculty of Psychology and Socio-Cultural Science
              </strong>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-item">
              Soekiman Wirjosandjojo Building, Kampus Terpadu UII <br> Jl. Kaliurang km. 14,5 Sleman, Yogyakarta 55584<br> Indonesia
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-item">
              Telepon: <a href="#">{{ telp }} ext. 2122</a><br>
              Email: <a :href="`mailto:${email}`" target="_blank">{{ email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      © Hak Cipta 2021 - Universitas Islam Indonesia
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      telp: null,
      fax: null,
      email: null,
    }
  },
  mounted() {
    this.getContact()
  },
  methods: {
    getContact() {
      this.$axios.get('/cms/footer')
        .then(response => {
          this.telp = response.data.data.telp
          this.fax = response.data.data.fax
          this.email = response.data.data.email
        })
    }
  }
}
</script>