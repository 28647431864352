<template>
  <header class="header" :class="{ 'for-login': $route.name == 'Login' }">
    <template v-if="$route.name == 'Login'">
      <div class="container">
        <router-link to="/home"><img src="/img/logo-login.svg" class="img-fluid"></router-link>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="nav-header">
          <div class="logo-part">
            <router-link to="/home">
              <img src="/img/logo.svg" class="logo img-fluid" alt="logo">
            </router-link>
          </div>
          <div class="menu-part">
            <ul class="main-menu">
              <li class="mm-list">
                <router-link to="/home" class="link-mm">Home</router-link>
              </li>
              <li class="mm-list">
                <router-link to="/room" class="link-mm">Room</router-link>
              </li>
              <li class="mm-list">
                <router-link to="/simulation" class="link-mm">Simulation</router-link>
              </li>
              <li class="mm-list">
                <router-link to="/dashboard" class="link-mm">Dashboard</router-link>
              </li>
            </ul>
          </div>
          <template v-if="$store.state.user">
            <div class="account-part">
              <div class="notif">
                <div class="box-notif">
                  <div class="notif-wrapper check-btn">
                    <input id="desktp" name="ntf" type="checkbox" @click="notif = !notif">
                    <label for="desktp" class="notif">
                      <div class="notif-count" v-if="badge > 0">{{ badge }}</div>
                    </label>
                  </div>
                  <div class="notif-inner right" :class="{ muncul: notif}">
                    <div class="box-notif-inner">
                      <div class="box-notif-head">
                        <div class="notif-inner-header">
                          Notification
                          <span class="count-for-notif" v-if="badge > 0">{{ badge }} New</span>
                        </div>
                        <div class="btn-close-notif">
                          <label for="desktp" class="btn btn-close"></label>
                        </div>
                      </div>
                      <div class="notif-inner-content">
                        <!-- <div class="notif-ttl">UNREAD</div> -->
                        <ul class="notif-list">
                          <template v-for="(data, index) in notifications">
                            <li :key="index">
                              <a href="#" class="item-notif" :class="{ unrd: index === null}" @click="read(data)">
                                <div class="inner-notif-text">
                                  <b>{{ data.title }}</b>
                                  <div class="text-notif">{{ data.description }}</div>
                                </div>
                                <div class="time-notif">
                                  {{ data.created_at }}
                                </div>
                              </a>
                            </li>
                          </template>
                        </ul>
                      </div>
                      <div class="box-notif-head" style="border-top: 1px solid #D9D9D9">
                        <div class="notif-inner-header" style="">
                          <a href="#" @click="readAll"> Read All </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile">
                <div class="box-profile">
                  <div class="for-img">
                    <div class="name-profile">
                      <h6>{{ $store.state.user.name }}</h6>
                      <template v-if="$store.getters.userType == 'master'">
                        Master User
                      </template>
                      <template v-else>
                        Student
                      </template>
                    </div>
                    <div class="img-profile">
                      <img v-if="$store.state.avatar === null" src="/img/default-profile-picture.png" class="img-fluid img-circle">
                      <img v-else :src="$store.state.avatar" class="img-fluid img-circle">
                      <div class="ol"></div>
                    </div>
                  </div>
                </div>

                <div class="profile-dropdown">
                  <router-link to="/profile" class="list-pd"><span class="icon-ico-user"></span>&nbsp; Profile</router-link>
                  <a @click="logout" :href="`${SSO_URL}/Shibboleth.sso/Logout?return=${BASE_URL}`" class="list-pd"><span class="icon-ico-logout"></span>&nbsp; Logout</a>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="notif-mobile">
                <div class="profile">
                  <div class="box-profile">
                    <div class="for-img">
                      <div class="name-profile">
                        <h6>{{ $store.state.user.name }}</h6>
                        <template v-if="$store.getters.userType == 'master'">
                          Master User
                        </template>
                        <template v-else>
                          Student
                        </template>
                      </div>
                      <div class="img-profile">
                        <img v-if="$store.state.avatar === null" src="/img/default-profile-picture.png" class="img-fluid img-circle">
                        <img v-else :src="$store.state.avatar" class="img-fluid img-circle">
                        <div class="ol"></div>
                      </div>
                    </div>
                  </div>

                  <div class="profile-dropdown">
                    <router-link to="/profile" class="list-pd"><span class="icon-ico-user"></span>&nbsp; Profile</router-link>
                    <a @click="logout" :href="`${SSO_URL}/Shibboleth.sso/Logout?return=${BASE_URL}`" class="list-pd"><span class="icon-ico-logout"></span>&nbsp; Logout</a>
                  </div>
                </div>
              </div>
              <div class="burger-part">
                <button class="btn btn-humberger"><span class="icon-burger"></span></button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="account-part">
              <div class="notif"></div>
              <div class="profile"></div>
              <div class="button-login">
                <a :href="`${SSO_URL}/Shibboleth.sso/Login`" class="btn btn-login w-100">Login</a>
              </div>
            </div>
            <div class="burger-part">
              <button class="btn btn-humberger"><span class="icon-burger"></span></button>
            </div>
          </template>
        </div>
      </div>

      <div class="nav-mobile" :class="{ 'logged-in': $store.state.user !== null }">
        <div class="nav-mobile-inner">
          <div class="nav-mobile-head">
            <div class="logo-mobile">
              <img src="/img/logo.svg" class="img-fluid">
            </div>
            <div>
              <button class="btn btn-close menu"></button>
            </div>
          </div>
          <div class="nav-mobile-content">
            <router-link to="/home" class="link-mobile">Home</router-link>
            <router-link to="/room" class="link-mobile">Room</router-link>
            <router-link to="/simulation" class="link-mobile">Simulation</router-link>
            <router-link to="/dashboard" class="link-mobile">Dashboard</router-link>
          </div>
          <div class="nav-mobile-footer">
            <div class="button-login">
              <a :href="`${SSO_URL}/Shibboleth.sso/Login`" class="btn btn-login w-100">Login</a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </header>
</template>

<script>
import $ from 'jquery';
import cookie from "js-cookie";

export default {
  name: "Header",
  data() {
    return {
      badge: 0,
      notifications: [],
      notif: false
    }
  },
  mounted() {
    $(".btn-humberger").click(function () {
      $('.nav-mobile').addClass("show");
      $('body').addClass("no-scroll");
    });
    $(".btn-close.menu").click(function () {
      $('.nav-mobile').removeClass("show");
      $('body').removeClass("no-scroll");
    });
    if (this.$store.state.user) {
      this.fetchNotifications();
      this.fetchTotalUnReadNotification();
    }
  },
  methods: {
    logout() {
      // clear cookie
      cookie.remove("userdata");
      cookie.remove("avatar");
      // clear vuex
      this.$store.commit("SET_USERDATA", null);
      // redirect after login
      // window.location.href = `${process.env.VUE_APP_SSO_URL}/Shibboleth.sso/Logout?return=${process.env.VUE_APP_BASE_URL}`;
    },
    onClose () {
      this.notif = false
    },
    fetchNotifications() {
      this.$axios.get('/notifications')
        .then(response => {
          console.log(response)
          this.notifications = response.data.data.data
        })
    },
    fetchTotalUnReadNotification() {
      this.$axios.get('/notifications/total_unread')
        .then(response => {
          console.log(response)
          this.badge = response.data.data.total_unread
        })
    },
    read(notif) {
      this.$axios.get(`/notifications/${notif.id}/read`)
        .then(response => {
          console.log(response)
          if (notif.route_page == 'room') {
            this.notif = false;
            this.fetchTotalUnReadNotification();
            this.$router.push(`/simulation/${notif.route_slug}`);
          }
        })
    },
    readAll() {
      this.$axios.get(`/notifications/read_all`)
        .then(response => {
          console.log(response)
          this.notif = false;
          this.fetchTotalUnReadNotification();
        })
    }
  }
}
</script>

<style scoped>
.muncul {
  visibility: visible !important;
  opacity: 1!important;
}
.unrd {
  background-color: #FEFCE5 !important
}
</style>