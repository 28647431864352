import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('../views/Forbidden.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/news',
    name: 'NewsList',
    component: () => import('../views/news/NewsList.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/news/:slug',
    name: 'NewsDetail',
    component: () => import('../views/news/NewsDetail.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/news/manage/list',
    name: 'NewsManage',
    component: () => import('../views/news/NewsManage.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/news/manage/create',
    name: 'NewsCreate',
    component: () => import('../views/news/NewsForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/news/manage/edit/:id',
    name: 'NewsEdit',
    component: () => import('../views/news/NewsForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/scenario',
    name: 'ScenarioList',
    component: () => import('../views/scenario/ScenarioList.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/scenario/:slug',
    name: 'ScenarioDetail',
    component: () => import('../views/scenario/ScenarioDetail.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/scenario/manage/list',
    name: 'ScenarioManage',
    component: () => import('../views/scenario/ScenarioManage.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/scenario/manage/create',
    name: 'ScenarioCreate',
    component: () => import('../views/scenario/ScenarioForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/scenario/manage/edit/:id',
    name: 'ScenarioEdit',
    component: () => import('../views/scenario/ScenarioForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/agenda',
    name: 'AgendaList',
    component: () => import('../views/agenda/AgendaList.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/agenda/manage/list',
    name: 'AgendaManage',
    component: () => import('../views/agenda/AgendaManage.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/agenda/manage/create',
    name: 'AgendaCreate',
    component: () => import('../views/agenda/AgendaForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/agenda/manage/edit/:id',
    name: 'AgendaEdit',
    component: () => import('../views/agenda/AgendaForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/room',
    name: 'RoomList',
    component: () => import('../views/room/RoomList.vue')
  },
  {
    path: '/room/:slug',
    name: 'RoomDetail',
    component: () => import('../views/room/RoomDetail.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/room/manage/create',
    name: 'RoomCreate',
    component: () => import('../views/room/RoomForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/room/manage/edit/:id',
    name: 'RoomEdit',
    component: () => import('../views/room/RoomForm.vue'),
    meta: {
      auth: true,
      userType: 'master'
    }
  },
  {
    path: '/simulation',
    name: 'SimulationList',
    component: () => import('../views/simulation/SimulationList.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/simulation/:slug',
    name: 'SimulationDetail',
    component: () => {
      if (store.getters.userType == 'master') {
        return import('../views/simulation/SimulationMaster.vue')
      } else {
        return import('../views/simulation/SimulationUser.vue')
      }
    },
    meta: {
      auth: true
    }
  },
  {
    path: '/simulation/:slug/position-paper/:id/:participantId',
    name: 'PositionPaper',
    component: () => import('../views/simulation/master/ParticipantTable/PositionPaperDetail.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  //linkExactActiveClass: "active",
  linkActiveClass: "active",
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.state.user == null) {
      window.location.href = `${process.env.VUE_APP_SSO_URL}/Shibboleth.sso/Login`
    }
  }
  if (to.matched.some(record => record.meta.guest)) {
    // this route check if user have logged in redirect to home page
    if (store.state.user !== null) {
      next('/dashboard')
    }
  }
  if (to.matched.some(record => record.meta.userType)) {
    const meta = to.matched.find(record => record.meta.userType)
    // this route check if user type
    if (meta.meta.userType !== store.getters.userType) {
      next('/dashboard')
    }
  }
  next()
})

export default router
