import Vue from "vue";
import * as moment from "moment";

Vue.mixin({
  methods: {
    formatDate(value, format = "YYYY-MM-DD") {
      return moment(value).format(format);
    },
    formatDateFromSource(value, sourceFormat, format = "YYYY-MM-DD") {
      return moment(value, sourceFormat).format(format);
    },
    disableInputMin (event) {
      if (event.code === 'Minus') {
        event.preventDefault()
      }
    }
  }
});

export default Vue;
